

import { Button, Checkbox, Form, Input, Collapse, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom"

const { Panel } = Collapse

const handleManagerChange = (value, form, setManagerCountCallback) => {
    form.setFieldValue("managers", value)
    setManagerCountCallback(value.length)
}

const FundDetails = (props) => {
    const params = useParams()
    const [managersCount, setManagerCount] = useState(0)
    const [currentPolicy] = useState("")
    const [raisedToken] = useState("")
    const [form] = useForm()
    return <div style={{ float: "left", width: "50%" }}><br></br>
        <Form
            disabled={true}
            initialValues={props.fundDetails[params.index]}
            // name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ offset: 1, span: 30 }}
            autoComplete="off"
            form={form}>
            <Collapse defaultActiveKey={[0, 1, 2, 3, 4]}>
                <Panel header="Fund Configuration" key={0}>
                    <Form.Item
                        label="FundName"
                        name="fundName"
                        rules={[{ required: true, message: 'Please input the fund name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Invest Policy"
                        name="policy"
                        rules={[{ required: true, message: 'Please select the policy!' }]}
                        initialValue={currentPolicy}
                    >
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item
                        label="Close Period"
                        name="closePeriod"
                        rules={[{ required: true, message: 'Please input the close period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Redeem Period"
                        name="redeemPeriod"
                        rules={[{ required: true, message: 'Please input the redeem period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Sponsor Divide Ratio"
                        name="sponsorCollapseatio"
                        rules={[{ required: true, message: 'Please input the sponsor divide ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} step={0.01} max={100} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Raise Configuration" key={1}>
                    <Form.Item
                        label="Raise Token"
                        name="raisedToken"
                        rules={[{ required: true, message: 'Please select the Raise Token!' }]}
                        initialValue={raisedToken}
                    >
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item
                        label="Raise Target"
                        name="raiseTarget"
                        rules={[{ required: true, message: 'Please input the raise target!' }]}
                        initialValue={1000}
                    >
                        <Input type="number" min={0} max={1e18} />
                    </Form.Item>
                    <Form.Item
                        label="Initial Net Value"
                        name="initialNetValue"
                        rules={[{ required: true, message: 'Please input the initial net value!' }]}
                        initialValue={0.1}
                    >
                        <Input type="number" min={0} step={0.01} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="MinRaise Value"
                        name="minRaiseShare"
                        rules={[{ required: true, message: 'Please input the initial net value!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="Raise Period"
                        name="raisePeriod"
                        rules={[{ required: true, message: 'Please input the raise period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Min Purchase Share"
                        name="minSharePurchase"
                        rules={[{ required: true, message: 'Please input the min share purchase!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="Max Purchase Share"
                        name="maxSharePurchase"
                        rules={[{ required: true, message: 'Please input the max share purchase!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="HardTop"
                        name="isHardTop"
                        valuePropName="checked"
                        initialValue={true}
                    ><Checkbox></Checkbox>
                    </Form.Item>
                </Panel>
                <Panel header="Bonus Configuration" key={2}>

                    <Form.Item
                        label="Bonus Period"
                        name="bonusPeriod"
                        rules={[{ required: true, message: 'Please input the bonus Period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={0} max={365 * 1} suffix="day(s)" />
                    </Form.Item>

                    <Form.Item
                        label="Bonus Ratio"
                        name="bonusRatio"
                        rules={[{ required: true, message: 'Please input the bonus ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} max={100} step={0.01} suffix="%" />
                    </Form.Item>
                    <Form.Item
                        label="Manager Bonus Divide Ratio"
                        name="managerBonusDivideRatio"
                        rules={[{ required: true, message: 'Please input the manager bonus divide ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} max={50} step={0.01} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Manage Configuration" key={3}>
                    <Form.Item
                        label="Managers"
                        name="managers"
                        rules={[{ required: true, message: 'Please select the managers!' }]}
                        initialValue={[]}
                    >
                        <Select mode="tags" placeholder="Please input the manager's address" onChange={(v) => { handleManagerChange(v, form, setManagerCount) }}>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Number Of Need Signed Addresses"
                        name="numberOfNeedSignedAddresses"
                        rules={[{ required: true, message: 'Please input the Number Of Need Signed Addresses!' }]}
                        initialValue={0}
                    >
                        <Input type="number" min={0} max={managersCount > 50 ? 50 : managersCount} />
                    </Form.Item>

                    <Form.Item
                        label="Manager Fee Ratio per day"
                        name="managerFeeRatio"
                        rules={[{ required: true, message: 'Please input the manager bonus divide ratio!' }]}
                        initialValue={0.000001}
                    >
                        <Input type="number" min={0} max={0.0001} step={0.000001} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Governor Configuration" key={4}>

                    <Form.Item
                        label="Voting Delay"
                        name="votingDelay"
                        rules={[{ required: true, message: 'Please input the Voting Delay!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={100000} suffix="block(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Voting Period"
                        name="votingPeriod"
                        rules={[{ required: true, message: 'Please input the Voting Period!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={100000} suffix="block(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Propsal Mininum Votes Need"
                        name="votingPeriod"
                        rules={[{ required: true, message: 'Please input the Voting Period!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={100000} suffix="block(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Bonus Period"
                        name="bonusPeriod"
                        rules={[{ required: true, message: 'Please input the bonus Period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={0} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Bonus Period"
                        name="bonusPeriod"
                        rules={[{ required: true, message: 'Please input the bonus Period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={0} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                </Panel>

            </Collapse>
        </Form>
        <Link to="/home"><Button type="primary" htmlType="cancel">
            Cancel
        </Button></Link></div >
}
export default FundDetails