import React from 'react'
import { Modal, Form, Space, Input, Button, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useForm } from 'antd/lib/form/Form';
import { useParams } from 'react-router-dom';
const ProposalModal = (props) => {
    const [form] = useForm()
    const params = useParams()
    const share = props.dataSource[params.fundName].userShare[props.address]
    const power = !share ? 0 : share
    const checkShare = () => {
        if (!power) {
            message.error(`you do not have no voting power,current have ${power}`)
        }
        if (props.proposal.yourVote && props.proposal.yourVote[props.address]) {
            message.error("you already voted")
            return
        }
        if (props.proposal.endBlock <= props.currentBlock) {
            message.error("proposal is end")
            return false
        }
        if (props.proposal.startBlock - 1 > props.currentBlock) {
            message.error("proposal is pending")
            return false
        }
        return power !== 0
    }
    return (
        <Modal closable={false} footer={[
            <Button key="cancel" onClick={() => {
                props.handleCancel()
            }}>
                Cancel
            </Button>,
            <Button type="primary" onClick={() => {
                if (checkShare()) {
                    props.handleOk(power)
                }
            }}>
                Agree
            </Button>,
            <Button type="primary" onClick={() => {
                if (checkShare()) {
                    props.handleAgainst(power)
                }
            }}>
                Against
            </Button>,
            <Button type="primary" onClick={() => {
                if (checkShare()) {
                    props.handleAbstain(power)
                }
            }}>
                Abstain
            </Button>,
        ]} open={props.isOpen} title="Proposal Detail" >
            <Form
                disabled={true}
                scrollToFirstError={true}
                labelCol={{ span: 6 }}
                initialValues={props.proposal}
                wrapperCol={{ offset: 1, span: 30 }}
                autoComplete="off"
                form={form}>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input the proposal title!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input the description" }]}>
                    <TextArea disabled={true}></TextArea>
                </Form.Item>
                <Form.List name="actions">
                    {(fields) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'target']}
                                        rules={[{ required: true, message: 'Missing target' }]}
                                    >
                                        <Input placeholder="Target" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder="Value" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'method']}
                                        rules={[{ required: true, message: 'Missing method' }]}
                                    >
                                        <Input placeholder="Method" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'calldata']}
                                        rules={[{ required: true, message: 'Missing calldata' }]}
                                    >
                                        <Input placeholder="Calldata" />
                                    </Form.Item>
                                </Space>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form >
        </Modal>
    )
}
export default ProposalModal
