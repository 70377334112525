import React from 'react'
import { Form, message, Input, Space, Button, Divider } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { useParams, useNavigate } from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea'
const CreatePropose = (props) => {
    const params = useParams()
    const fundName = params.fundName
    const [form] = useForm()
    const navigator = useNavigate()
    const onFinish = (values) => {
        const share = props.dataSource[fundName].userShare[props.address]
        const fundDetail = props.fundDetails[fundName]
        if (fundDetail.proposalNeed > share) {
            message.error(`propose Need token ${fundDetail.proposalNeed}, you only have ${share}`)
            return;
        }
        props.onProposalCreate(params.fundName, values)
        navigator("/governor/" + fundName)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Form
            scrollToFirstError={true}
            labelCol={{ span: 6 }}
            wrapperCol={{ offset: 1, span: 30 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}>
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input the proposal title!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input the description" }]}>
                <TextArea></TextArea>
            </Form.Item>
            <Form.List label="Actions" name="actions">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    label="Action"
                                    {...restField}
                                    name={[name, 'target']}
                                    rules={[{ required: true, message: 'Missing target' }]}
                                >
                                    <Input placeholder="Target" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'value']}
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder="Value" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'method']}
                                    rules={[{ required: true, message: 'Missing method' }]}
                                >
                                    <Input placeholder="Method" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'calldata']}
                                    rules={[{ required: true, message: 'Missing calldata' }]}
                                >
                                    <Input placeholder="Calldata" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Action
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Divider type="vertical"></Divider>
                <Link to={"/governor/" + fundName}><Button type="primary" htmlType="cancel">
                    Cancel
                </Button></Link>
            </Form.Item>
        </Form >
    )
}
export default CreatePropose;
