import { Modal, Table } from "antd";
import React from "react";

export default class SelectPolicy extends React.Component {
    state = { currentPolicy: "0x0" }
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ currentPolicy: selectedRows[0].name })
        },
    }
    componentDidMount() {
    }
    render() {
        return <Modal open={this.props.isOpen} title="Please Select" onCancel={() => this.props.handleCancel()} onOk={() => this.props.handleOk(this.state.currentPolicy)}>
            <Table rowSelection={{
                ...this.rowSelection, ...{
                    type: this.props.type
                }
            }} dataSource={this.props.dataSource} columns={this.props.columns} />
        </Modal>
    }
}