import { Link, useParams } from "react-router-dom"
import React, { useState } from "react"
import { Table, Button, message } from "antd"
import ProposalModal from "../components/proposalModal";

let columns = [
    {
        title: "Id",
        dataIndex: "key",
        key: "key",
    },
    {
        title: 'Proposal Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Proposer',
        dataIndex: 'proposer',
        key: 'proposer',
    },
    {
        title: 'Start Block',
        dataIndex: 'startBlock',
        key: 'startBlock'
    },
    {
        title: 'End Block',
        dataIndex: 'endBlock',
        key: 'endBlock',
    },
    {
        title: 'Agree',
        dataIndex: 'agree',
        key: 'agree',
    },
    {
        title: 'Against',
        dataIndex: 'against',
        key: 'against',
    },
    {
        title: 'Abstain',
        dataIndex: 'abstain',
        key: 'abstain',
    },
];
const FundGovernor = (props) => {
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [proposalDetail, setProposal] = useState({})
    const cs = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, rec) => {
                const fundDetail = props.fundDetails[params.fundName]
                const proposal = props.governorDetails[params.fundName][rec.key - 1]
                if (proposal.isCancel) {
                    return "canceled"
                } else if (rec.startBlock > props.currentBlock) {
                    return "pending"
                } else if (rec.endBlock > props.currentBlock) {
                    return "voting"
                } else if (rec.agree <= rec.against || rec.agree < fundDetail.voteNeed) {
                    return "defeated"
                } else if (proposal.isExecute) {
                    return "executed"
                } else {
                    return "succeeded"
                }
            }
        }, ...columns, {
            title: 'Your Voting Power',
            dataIndex: 'votingPower',
            key: 'votingPower',
            render: (_, rec) => {
                return <div>{props.dataSource[params.fundName].userShare ? props.dataSource[params.fundName].userShare[props.address] : 0}</div>
            }
        }, {
            title: 'Your Vote',
            dataIndex: 'yourVote',
            key: 'yourVote',
            render: (_, rec) => {
                return <div>{rec.yourVote && rec.yourVote[props.address] ? (rec.yourVote[props.address].value + " " + (rec.yourVote[props.address].type) ?? 0) : 0}</div>
            }
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record, index) => {
                setProposal(record)
                return (
                    <div>
                        <Button onClick={() => {
                            setOpen(true)
                        }}>Detail</Button>
                    </div>
                )
            },
        }
    ]
    const data = props.governorDetails[params.fundName] ? [...props.governorDetails[params.fundName]] : []
    return <div><Link to={"/createPropose/" + params.fundName} disabled={!props.isLogin}><Button disabled={!props.isLogin}>Propose</Button></Link>
        <Table dataSource={data} columns={cs} />
        <ProposalModal proposal={proposalDetail} isOpen={open} {...props}
            handleCancel={() => {
                setOpen(false)
            }}
            handleOk={(v) => {
                proposalDetail.agree += v
                if (!proposalDetail.yourVote) {
                    proposalDetail.yourVote = {}
                }
                proposalDetail.yourVote[props.address] = { type: "agree", value: v }
                setOpen(false)
                props.onProposalUpdate(params.fundName, proposalDetail)
            }}
            handleAgainst={(v) => {
                proposalDetail.against += v
                if (!proposalDetail.yourVote) {
                    proposalDetail.yourVote = {}
                }
                proposalDetail.yourVote[props.address] = { type: "against", value: v }
                setOpen(false)
                props.onProposalUpdate(params.fundName, proposalDetail)
            }}
            handleAbstain={(v) => {
                proposalDetail.abstain += v
                if (!proposalDetail.yourVote) {
                    proposalDetail.yourVote = {}
                }
                proposalDetail.yourVote[props.address] = { type: "abstain", value: v }
                setOpen(false)
                props.onProposalUpdate(params.fundName, proposalDetail)
            }}
        ></ProposalModal>
    </div>
}
export default FundGovernor