

import { Button, Checkbox, Form, Input, Collapse, Divider, Select, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import SelectPolicy from '../components/selectPolicyModal';

const { Panel } = Collapse
const handleModalOk = (type, value, form) => {
    switch (type) {
        case "token":
            form.setFieldValue("raisedToken", value)
            break;
        case "policy":
            form.setFieldValue("policy", value)
            break;
        case "managers":
            form.setFieldValue("managers", value)
            break;
        default:
            break;
    }
}

const handleManagerChange = (value, form, setManagerCountCallback) => {
    form.setFieldValue("managers", value)
    setManagerCountCallback(value.length)
}

const CreateFund = (props) => {
    const [isModalShow, setModalShow] = useState(false)
    const [modalType, setModalType] = useState("")
    const [modalDataSource, setModalDataSource] = useState([])
    const [modalColums, setModalColums] = useState([])
    const [tableType, setTableType] = useState("radio")
    const [managersCount, setManagerCount] = useState(0)
    const [currentPolicy] = useState("")
    const [raisedToken] = useState("")
    const [form] = useForm()
    const navigator = useNavigate()
    const onFinish = (values) => {
        if (props.fundDetails[values.fundName]) {
            message.error("Fund Exist")
            return;
        }
        props.onFundChange({
            name: values.fundName,
            sponsor: props.address,
            managers: values.managers,
            token: values.raisedToken,
            initNetValue: values.initialNetValue,
            currentNetValue: values.initialNetValue,
            totalShare: 0,
            userShare: {},
            volumn: 0,
        }, values)
        navigator("/home")
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return <div style={{ float: "left", width: "50%" }}><br></br>
        <Form
            // name="basic"
            scrollToFirstError={true}
            labelCol={{ span: 6 }}
            wrapperCol={{ offset: 1, span: 30 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}>
            <Collapse defaultActiveKey={[0, 1, 2, 3, 4]}>
                <Panel header="Fund Configuration" key={0}>
                    <Form.Item
                        label="FundName"
                        name="fundName"
                        rules={[{ required: true, message: 'Please input the fund name!' }]}
                        initialValue="123"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Invest Policy"
                        name="policy"
                        rules={[{ required: true, message: 'Please select the policy!' }]}
                        initialValue="uniswap series"
                    // initialValue={currentPolicy}
                    >
                        <Input readOnly={true} onClick={() => {
                            setModalType("policy")
                            setTableType("radio")
                            setModalDataSource([
                                { name: "uniswap series", creator: "Kun", defis: ["uniswapV2", "uniswapV3"].join(", "), key: "0x123" },
                                { name: "lending series", creator: "Kun", defis: ["compound", "aave"].join(", "), key: "0x321" }
                            ])
                            setModalColums([
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Creator',
                                    dataIndex: 'creator',
                                    key: 'creator',
                                },
                                {
                                    title: 'DEFIs',
                                    dataIndex: 'defis',
                                    key: 'defis',

                                }
                            ])
                            setModalShow(true)
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="Close Period"
                        name="closePeriod"
                        rules={[{ required: true, message: 'Please input the close period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Redeem Period"
                        name="redeemPeriod"
                        rules={[{ required: true, message: 'Please input the redeem period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Sponsor Divide Ratio"
                        name="sponsorCollapseatio"
                        rules={[{ required: true, message: 'Please input the sponsor divide ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} step={0.01} max={100} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Raise Configuration" key={1}>
                    <Form.Item
                        label="Raise Token"
                        name="raisedToken"
                        rules={[{ required: true, message: 'Please select the Raise Token!' }]}
                        initialValue="USDC"
                    // initialValue={raisedToken}
                    >
                        <Input readOnly={true} onClick={() => {
                            setModalType("token")
                            setTableType("radio")
                            setModalDataSource([
                                { name: "USDT", currentPrice: 1.0, key: "0x456" },
                                { name: "USDC", currentPrice: 1.0, key: "0x654" },
                                { name: "ETH", currentPrice: 1500.0, key: "0x789" },
                                { name: "BTC", currentPrice: 20000.0, key: "0x987" },
                            ])
                            setModalColums([
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'CurrentPrice',
                                    dataIndex: 'currentPrice',
                                    key: 'currentPrice',

                                }
                            ])
                            setModalShow(true)
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="Raise Target"
                        name="raiseTarget"
                        rules={[{ required: true, message: 'Please input the raise target!' }]}
                        initialValue={1000}
                    >
                        <Input type="number" min={0} max={1e18} />
                    </Form.Item>
                    <Form.Item
                        label="Initial Net Value"
                        name="initialNetValue"
                        rules={[{ required: true, message: 'Please input the initial net value!' }]}
                        initialValue={0.1}
                    >
                        <Input type="number" min={0} step={0.01} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="MinRaise Value"
                        name="minRaiseShare"
                        rules={[{ required: true, message: 'Please input the initial net value!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="Raise Period"
                        name="raisePeriod"
                        rules={[{ required: true, message: 'Please input the raise period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={1} max={365 * 1} suffix="day(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Min Purchase Share"
                        name="minSharePurchase"
                        rules={[{ required: true, message: 'Please input the min share purchase!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="Max Purchase Share"
                        name="maxSharePurchase"
                        rules={[{ required: true, message: 'Please input the max share purchase!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={10000} />
                    </Form.Item>
                    <Form.Item
                        label="HardTop"
                        name="isHardTop"
                        valuePropName="checked"
                        initialValue={true}
                    ><Checkbox></Checkbox>
                    </Form.Item>
                </Panel>
                <Panel header="Bonus Configuration" key={2}>

                    <Form.Item
                        label="Bonus Period"
                        name="bonusPeriod"
                        rules={[{ required: true, message: 'Please input the bonus Period!' }]}
                        initialValue={30}
                    >
                        <Input type="number" min={0} max={365 * 1} suffix="day(s)" />
                    </Form.Item>

                    <Form.Item
                        label="Bonus Ratio"
                        name="bonusRatio"
                        rules={[{ required: true, message: 'Please input the bonus ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} max={100} step={0.01} suffix="%" />
                    </Form.Item>
                    <Form.Item
                        label="Manager Bonus Divide Ratio"
                        name="managerBonusDivideRatio"
                        rules={[{ required: true, message: 'Please input the manager bonus divide ratio!' }]}
                        initialValue={0.01}
                    >
                        <Input type="number" min={0} max={50} step={0.01} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Manage Configuration" key={3}>
                    <Form.Item
                        label="Managers"
                        name="managers"
                        rules={[{ required: true, message: 'Please select the managers!' }]}
                        initialValue={["0x159"]}
                    >
                        <Select mode="tags" placeholder="Please input the manager's address" onChange={(v) => { handleManagerChange(v, form, setManagerCount) }}>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Number Of Need Signed Addresses"
                        name="numberOfNeedSignedAddresses"
                        rules={[{ required: true, message: 'Please input the Number Of Need Signed Addresses!' }]}
                        initialValue={0}
                    >
                        <Input type="number" min={0} max={managersCount > 50 ? 50 : managersCount} />
                    </Form.Item>

                    <Form.Item
                        label="Manager Fee Ratio per day"
                        name="managerFeeRatio"
                        rules={[{ required: true, message: 'Please input the manager bonus divide ratio!' }]}
                        initialValue={0.000001}
                    >
                        <Input type="number" min={0} max={0.0001} step={0.000001} suffix="%" />
                    </Form.Item>
                </Panel>
                <Panel header="Governor Configuration" key={4}>

                    <Form.Item
                        label="Voting Delay"
                        name="votingDelay"
                        rules={[{ required: true, message: 'Please input the Voting Delay!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={100000} suffix="block(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Voting Period"
                        name="votingPeriod"
                        rules={[{ required: true, message: 'Please input the Voting Period!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} max={100000} suffix="block(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Mininum Votes Need"
                        name="voteNeed"
                        rules={[{ required: true, message: 'Please input the Propsal Mininum Votes Need!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} suffix="token(s)" />
                    </Form.Item>
                    <Form.Item
                        label="Mininum Propose Need"
                        name="proposalNeed"
                        rules={[{ required: true, message: 'Please input the Propsal Mininum Votes Need!' }]}
                        initialValue={1}
                    >
                        <Input type="number" min={0} suffix="token(s)" />
                    </Form.Item>
                </Panel>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Divider type="vertical"></Divider>
                    <Link to="/home"><Button type="primary" htmlType="cancel">
                        Cancel
                    </Button></Link>
                </Form.Item></Collapse>
        </Form><SelectPolicy
            dataSource={modalDataSource}
            columns={modalColums}
            handleOk={(value) => {
                handleModalOk(modalType, value, form)
                setModalShow(false)
            }}
            type={tableType}
            handleCancel={() => setModalShow(false)}
            isOpen={isModalShow}
            currentPolicy={currentPolicy}>
        </SelectPolicy></div >
}
export default CreateFund