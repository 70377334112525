import { Button, Table } from 'antd';
import React from 'react';
import { Link } from "react-router-dom"
import FundTradeModal from '../components/fundTradeModal';
let columns = [
    {
        title: 'Fund Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Sponsor',
        dataIndex: 'sponsor',
        key: 'sponsor',
    },
    {
        title: 'Fund Managers',
        dataIndex: 'managers',
        key: 'managers',
        render: (_, item) => {
            return item.managers.join(",")
        }
    },
    {
        title: 'token',
        dataIndex: 'token',
        key: 'token',
    },
    {
        title: 'Locked Value',
        dataIndex: 'volumn',
        key: 'volumn',
    },
    {
        title: 'Initial Net Value',
        dataIndex: 'initNetValue',
        key: 'initNetValue',
    },
    {
        title: 'Current Net Value',
        dataIndex: 'currentNetValue',
        key: 'currentNetValue',
    },
    {
        title: 'Total Share',
        dataIndex: 'totalShare',
        key: 'totalShare',
    },
];


export default class FundList extends React.Component {
    state = {
        modalOpen: false,
        token: "",
        modifyFund: "",
    }
    render() {
        let cs = [...columns,
        {
            title: 'Your Share',
            dataIndex: 'userShare',
            key: 'userShare',
            render: (_, rec) => {
                return rec.userShare[this.props.address] ?? 0
            }
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                let stateObj = { modalOpen: true, modifyFund: record.name, isBuy: true, token: record.token, price: record.currentNetValue }
                return (
                    <div>
                        <Button size="middle" disabled={!this.props.isLogin} onClick={(e) => {
                            stateObj.isBuy = true
                            this.setState(stateObj)
                        }}>
                            Buy
                        </Button>
                        <Button size="middle" disabled={!this.props.isLogin} onClick={() => {
                            stateObj.isBuy = false
                            this.setState(stateObj)
                        }}>
                            Redeem
                        </Button>
                        <Link to={"/fundDetail/" + record.name} ><Button size="middle" >
                            Detail
                        </Button></Link>
                        <Link to={"/governor/" + record.name} ><Button size="middle" >
                            governor
                        </Button></Link>
                    </div>
                )
            },
        }]
        let arr = []
        for (var key in this.props.dataSource) {
            arr.push(this.props.dataSource[key]);
        }
        const userShare = !this.props.dataSource[this.state.modifyFund] ? {} : this.props.dataSource[this.state.modifyFund].userShare;
        return (<div>
            <Link to="/createFund" disabled={!this.props.isLogin}><Button disabled={!this.props.isLogin}>Create Fund</Button></Link>
            <Table rowKey={(r) => {
                return r.name
            }} dataSource={arr} columns={cs} />
            <FundTradeModal open={this.state.modalOpen}
                userShare={!this.state.modifyFund ? 0 : (userShare[this.props.address] ?? 0)}
                userBalance={!this.state.modifyFund ? 0 : (this.props.userBalance[this.props.address] ? this.props.userBalance[this.props.address][this.props.dataSource[this.state.modifyFund].token] : {})}
                isBuy={this.state.isBuy}
                token={this.state.token}
                price={this.state.price}
                handleOk={(s) => {
                    let obj = { ...this.props.dataSource[this.state.modifyFund] }
                    const t = obj.token
                    if (!userShare[this.props.address]) {
                        userShare[this.props.address] = 0
                    }
                    if (this.state.isBuy) {
                        obj.userShare[this.props.address] += s.share
                        obj.totalShare += s.share
                        obj.volumn += s.amount
                        this.props.onBalanceChange(t, -s.amount)
                    } else {
                        obj.userShare[this.props.address] -= s.share
                        obj.totalShare -= s.share
                        obj.volumn -= s.amount
                        this.props.onBalanceChange(t, +s.amount)
                    }
                    this.props.onFundChange(obj)
                    this.setState({ modalOpen: false })
                }} handleCancel={(s) => {
                    this.setState({ modalOpen: false })
                }}></FundTradeModal>
        </div>
        );
    }
}