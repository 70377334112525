
import ReactDOM from 'react-dom/client';
import './index.css';
import "./pages/index"
import reportWebVitals from './reportWebVitals';
import FundList from './pages/index';
import { Divider, Button, Layout, Menu, Modal, Input, message } from 'antd';
import React from 'react';
import { Route, Routes, Link, BrowserRouter } from "react-router-dom"
import CreateFund from "./pages/createFund"
import CreatePropose from "./pages/createPropose"
import { Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import FundDetails from './pages/fundDetails';
import FundGovernor from './pages/fundGovernors';
const { Content, Footer, } = Layout;
const root = ReactDOM.createRoot(document.getElementById('root'));
const menus = [
  { label: <Link to="/home">Home</Link>, key: "home" },
  { label: <Link to="/home">About</Link>, key: "about" },
  // { label: <Link to="/policy">Invest Policy</Link>, key: "policy" },
  // { label: <Link to="/funds">Funds</Link>, key: "funds" }
]
class MainApp extends React.Component {
  state = {
    isLogin: false,
    address: "unknown",
    buttonText: "Connect Wallet",
    userBalance: {

    },
    currentBlock: 0,
    governorDetails: {

    },
    fundDetails: {

    },
    dataSource: {}
    // {
    //   key: '1',
    //   name: 'Air Force No.1',
    //   sponsor: "Kun",
    //   managers: ["Kun", "ryan"],
    //   token: "BTC",
    //   initNetValue: 1.0,
    //   currentNetValue: 1.1,
    //   totalShare: 10000,
    //   yourShare: 0,
    //   volumn: 11000,
    // },
    // {
    //   key: '2',
    //   name: 'Air Force No.2',
    //   sponsor: "Kun",
    //   managers: ["Kun", "ryan"],
    //   token: "ETH",
    //   initNetValue: 1.0,
    //   currentNetValue: 1.2,
    //   totalShare: 50000,
    //   yourShare: 0,
    //   volumn: 60000,
    // }
  }
  setAddress(val) {
    this.setState({ address: val })
  }
  setLogin(val) {
    this.setState({ isLogin: val })
  }
  setButtonText(val) {
    this.setState({ buttonText: val })
  }
  render() {
    const balanceChange = (token, val) => {
      let userBalance = { ...this.state.userBalance }
      if (userBalance[this.state.address]) {
        let userBalanceObj = { ...userBalance[this.state.address] }
        userBalanceObj[token] += val
        userBalance[this.state.address] = { ...userBalanceObj }
        this.setState({ userBalance: userBalance })
      }
    }
    const fundChange = (val, detail) => {
      let dataSourceObj = { ...this.state.dataSource }
      let detailsObj = { ...this.state.fundDetails }
      if (detail) {
        detailsObj[detail.fundName] = detail
      }
      dataSourceObj[val.name] = val
      this.setState({ dataSource: dataSourceObj, fundDetails: detailsObj, currentBlock: this.state.currentBlock + 1 })
    }
    const proposalCreate = (fundName, proposal) => {
      const fund = this.state.fundDetails[fundName]
      let goverNorDetailsObj = {
        ...this.state.governorDetails
      }
      let governorDetails = [...(goverNorDetailsObj[fundName] ?? [])]
      proposal.key = governorDetails.length + 1;
      proposal.proposer = this.state.address
      proposal.startBlock = this.state.currentBlock + 1 + fund.votingDelay
      proposal.endBlock = proposal.startBlock + fund.votingPeriod
      proposal.agree = 0
      proposal.against = 0
      proposal.abstain = 0
      proposal.yourVote = {
      }
      governorDetails.push(proposal)
      goverNorDetailsObj[fundName] = governorDetails
      this.setState({ governorDetails: goverNorDetailsObj, currentBlock: this.state.currentBlock + 1 })
    }
    const proposalUpdate = (fundName, newProposal) => {
      let governorDetailsObj = {
        ...this.state.governorDetails
      }
      let governorDetails = [...(governorDetailsObj[fundName] ?? [])]
      let proposal = governorDetails[newProposal.key - 1]
      if (proposal) {
        governorDetails[newProposal.key - 1] = { ...newProposal }
        governorDetailsObj[fundName] = [...governorDetails]
        this.setState({ governorDetails: governorDetailsObj, currentBlock: this.state.currentBlock + 1 })
      }
    }
    let address = "";
    return <Layout
      style={{
        minHeight: '100vh',
      }}><Modal open={!this.state.isLogin} onOk={() => {
        if (!address) {
          message.error("Please Input your wallet address")
          return;
        }
        let userBalance = this.state.userBalance[address]
        if (!userBalance) {
          userBalance = {
            "ETH": 1000,
            "USDT": 1000000,
            "USDC": 1000000,
            "BTC": 200
          }
        }
        let userBalances = { ...this.state.userBalance }
        userBalances[address] = userBalance
        this.setState({ address: address, buttonText: "Log Out", isLogin: !this.state.isLogin, userBalance: userBalances })
      }} onCancel={() => {
        this.setLogin(!this.state.isLogin)
      }}><Input placeholder='Please Input your wallet address' onChange={(item) => {
        address = "0x" + (item.target.value)
      }}></Input></Modal>
      <Header><div style={{ float: "right", color: "white" }}>CurrentBlock:{this.state.currentBlock}<Divider type="vertical" style={{ color: "white" }} />
        {this.state.address ? this.state.address : ""}<Divider type="vertical" style={{ color: "white" }} /><Button onClick={() => {
          if (!this.state.isLogin) {
            this.setAddress("")
            this.setButtonText("Log Out")
          } else {
            this.setAddress("")
            this.setButtonText("Connect Wallet")
          }
          this.setLogin(!this.state.isLogin)
        }} >{this.state.buttonText}</Button>
      </div></Header>
      <Layout className="site-layout" hasSider={true}>
        <Sider> <Menu items={menus}></Menu></Sider>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Routes>
            <Route path="/createFund" element={<CreateFund history={this.context.history} onFundChange={fundChange} onBalanceChange={balanceChange} {...this.state} />}></Route>
            <Route path="/fundDetail/:index" element={<FundDetails {...this.state} />}></Route>
            <Route path="/createPropose/:fundName" element={<CreatePropose onProposalCreate={proposalCreate} {...this.state}></CreatePropose>}></Route>
            <Route path="/governor/:fundName" element={<FundGovernor onProposalUpdate={proposalUpdate} {...this.state}></FundGovernor>}></Route>
            <Route path="/home" element={<FundList onFundChange={fundChange} onBalanceChange={balanceChange} {...this.state}></FundList>}></Route>
            <Route path="/" element={<FundList onFundChange={fundChange} onBalanceChange={balanceChange} {...this.state}></FundList>}></Route>
          </Routes>
        </Content>
      </Layout>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Pyxis Protocols ©2022 Created by Ugly
      </Footer>
    </Layout >
  }
}
root.render(
  <BrowserRouter>
    <MainApp></MainApp>
  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
