
import React from "react";
import { message, Modal, InputNumber } from "antd";

class FundTradeModal extends React.Component {
    state = {
        isApproved: {},
        amount: 0,
        share: 0
    }
    componentDidUpdate() {
        if (!this.props.isBuy && this.props.userShare < this.state.share) {
            message.error('share not enough');
        }
        else if (this.props.isBuy && this.props.userBalance < this.state.amount) {
            message.error('balance not enough');
        }
    }
    render() {
        const title = (this.props.isBuy ? "Buy" : "Redeem")
        return <Modal open={this.props.open} title={title + " Fund"} okText={this.state.isApproved[this.props.token] ? title : "Approved"} onCancel={() => {
            this.setState({
                amount: 0,
                share: 0
            })
            this.props.handleCancel()
        }} onOk={() => {
            if (!this.state.isApproved[this.props.token]) {
                let approved = {}
                approved[this.props.token] = true
                this.setState({ isApproved: approved })
                return
            }
            this.props.handleOk(this.state)
            this.setState({
                amount: 0,
                share: 0
            })
        }}>
            {"Your Share: " + this.props.userShare + " PCS"}<br></br>{"Your Balance: " + this.props.userBalance + " " + this.props.token}<br></br>
            Fund Share:<InputNumber style={{ width: "100%" }} min={0} prefix="PCS" value={this.state.share} onChange={(v) => {
                this.setState({ share: v, amount: v * this.props.price })
            }}></InputNumber>
            Token Amount:<InputNumber style={{ width: "100%" }} min={0} prefix={this.props.token} value={this.state.amount} onChange={(v) => {
                this.setState({ share: v / this.props.price, amount: v })
            }}></InputNumber>
        </Modal>
    }
}
export default FundTradeModal